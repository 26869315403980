<template>
  <div class="Exchange">
    <div class="step">
      <div class="step-top">
        <div class="st-circle">
          <div class="stc-in"></div>
        </div>
        <div class="st-line"></div>
        <div class="st-circle">
          <div class="stc-in"></div>
        </div>
        <div class="st-line"></div>
        <div class="st-circle">
          <div class="stc-in"></div>
        </div>
      </div>
      <div class="step-bottom">
        <div class="sb-item">{{$t('exchange.into')}}{{systemInfo.tkname}}</div>
        <div class="sb-item">{{$t('exchange.step_2')}}</div>
        <div class="sb-item">{{$t('exchange.step_3')}}</div>
      </div>
    </div>
    <div class="rate">
      {{$t('exchange.rate_1')}}{{pledgeCycle}}{{$t('exchange.rate_2')}}{{rateInfo.quote}}{{systemInfo.tkname}},{{$t('exchange.rate_3')}}{{rateInfo.rate}}%
      <span v-if="rateInfo.quoteNext && rateInfo.rateNext">,{{$t('exchange.rate_4')}}{{rateInfo.quoteNext}}{{systemInfo.tkname}},{{$t('exchange.rate_5')}}{{rateInfo.rateNext}}%.</span>
    </div>
    <div class="main">
      <div class="main-title" style="align-items: flex-start;">
        <div class="mt-text">{{$t('exchange.pledged')}} <img @click="openNotice" class="mt-img" src="@/assets/images/icon_Tooltip.png" alt=""></div>
        <div class="mt-balance">
          <div class="">{{mudBalance}} MUD</div>
          <div class="">{{ usdtBalance }} USDT</div>
        </div>
      </div>
      <div class="main-title">
        <div class="mt-text">{{$t('exchange.pledgeNum')}}</div>
      </div>
      <div class="main-symbol"></div>
      <div class="main-list">
        <div 
          class="main-item"
          v-for="(o, i) in pledgeArr" 
          :key="i"
          :class="{'main-item-active': pledgeIndex == i && num == ''}"
          @click="pledgeIndex = i; num = ''"
        >
          {{o.value}}
          <div class="mi-rate">{{o.rate}}%</div>
        </div>
      </div>
      <div class="main-input">
        <input type="text" v-model="num" :placeholder="`${$t('exchange.minPledge')}${tokenInfo.min || 0})`">
        <div class="mi-all" @click="num = mudBalance">{{$t('common.all')}}</div>
      </div>
      <div class="main-title">
        <div class="mt-text">{{$t('exchange.cycle')}}</div>
      </div>
      <div class="main-symbol"></div>
      <div class="main-list">
        <div 
          class="main-item"
          v-for="(o, i) in tokenInfo.cycle" 
          :key="i"
          :class="{'main-item-active': cycleIndex == i}"
          @click="cycleIndex = i"
        >
          {{o.value}}{{$t('common.day')}} {{o.rate}}%
        </div>
      </div>
      <div class="main-title">
        <div class="mt-text">{{$t('exchange.note')}}</div>
        <div class="mt-select" @click="pickNode">{{$t('exchange.choosenote')}} ></div>
      </div>
      <div class="main-symbol"></div>
      <div class="main-node" @click="pickNode">
        <img class="mn-logo" :src="nodeInfo.logo" alt="">
        <div class="mn-name">{{nodeInfo.name}}</div>
        <img class="mn-img" src="@/assets/images/icon_DownArrow.png" alt="">
      </div>
      <div class="main-title">
        <div class="mt-text">{{$t('exchange.slide')}}</div>
      </div>
      <div class="main-symbol"></div>
      <div class="main-list">
        <div 
          class="main-item"
          v-for="(o, i) in slideArr" 
          :key="i"
          :class="{'main-item-active': slideIndex == i && slideNum == ''}"
          @click="slideIndex = i; slideNum = ''"
        >
          {{o.value}}%
        </div>
      </div>
      <div class="main-input">
        <input type="text" v-model="slideNum" :placeholder="`${$t('exchange.slide_tips')}`">
        <div class="mi-all">%</div>
      </div>
      <div class="slide-tips">{{$t('exchange.slide_notice')}}</div>
      <div class="main-tips">
        <div class="">{{$t('exchange.pledgeNum_1')}}{{pledgeNum}}{{systemInfo.tkname}}</div>
        <div class="">{{$t('exchange.speed')}}{{pledgeUp}} <img src="@/assets/images/icon_Arrow.png" alt=""></div>
      </div>
      <!-- <div class="main-btn">
        <CommonBtn :btnText="$t('exchange.pledge')" @btnClick="openContent"></CommonBtn>
      </div> -->
      <div class="main-record" @click="toRecord">{{$t('exchange.record')}}</div>
    </div>
    <div class="rule">
      <div class="rule-head">
        <div class="rh-item" :class="{'rh-item-active': ruleIndex == 5}" @click="changeRuleIndex(5)">{{$t('exchange.rule_1')}}</div>
        <div class="rh-item" :class="{'rh-item-active': ruleIndex == 4}" @click="changeRuleIndex(4)">{{$t('exchange.rule_2')}}</div>
        <div class="rh-item" :class="{'rh-item-active': ruleIndex == 3}" @click="changeRuleIndex(3)">{{$t('exchange.rule_3')}}</div>
      </div>
      <div class="rule-main" v-html="ruleInfo.content"></div>
    </div>
    <van-popup
      v-model:show="visible"
      position="bottom"
      round
      class="ConfirmContent"
    >
      <div class="content" v-if="visibleType == 1">
        <div class="head">
          <div class="title">{{$t('exchange.confirm')}}</div>
          <img @click="visible = false" src="@/assets/images/icon_Delete.png" alt="">
        </div>
        <div class="symbol"></div>
        <div class="list">
          <div class="item">
            <div class="key">{{$t('exchange.pledgeNum')}}：</div>
            <div class="value">{{pledgeNum}} {{systemInfo.tkname}} | {{ usdtPledge }} USDT</div>
          </div>
          <div class="item">
            <div class="key"></div>
            <div class="value notice">{{$t('success.tips')}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('exchange.cycle')}}：</div>
            <div class="value">{{param.cycle}} {{$t('common.day')}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('exchange.note_2')}}：</div>
            <div class="value">{{nodeInfo.name}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('exchange.speed_2')}}：</div>
            <div class="value">{{pledgeUp}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('exchange.slide')}}：</div>
            <div class="value">{{showSlideNum}}%</div>
          </div>
          <div class="item">
            <div class="key">GAS{{$t('withdraw.fee')}}：</div>
            <div class="value">{{$t('withdraw.consume')}}MATIC</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="list">
          <div class="item">
            <div class="key">{{$t('withdraw.wallet')}}{{systemInfo.tkname}}{{$t('withdraw.balance')}}：</div>
            <div class="value">{{mudBalance}} {{systemInfo.tkname}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('withdraw.wallet')}}USDT{{$t('withdraw.balance')}}：</div>
            <div class="value">{{usdtBalance}} USDT</div>
          </div>
          <div class="item">
            <div class="key">{{$t('withdraw.wallet')}}MATIC{{$t('withdraw.balance')}}</div>
            <div class="value">{{ethBalance}} MATIC</div>
          </div>
        </div>
        <div class="notice" v-if="ethBalance < 10">
          <img src="@/assets/images/icon_Notes_red.png" alt="">
          <div class="">{{$t('withdraw.notice')}}</div>
        </div>
        <div class="confirm-btn">
          <CommonBtn :btnText="$t('exchange.btn_1')" :loading="btnLoading" @btnClick="buyTokens"></CommonBtn>
        </div>
      </div>
      <div class="content" v-else>
        <div class="head">
          <div class="title">{{$t('exchange.title_1')}}</div>
          <img @click="visible = false" src="@/assets/images/icon_Delete.png" alt="">
        </div>
         <div class="symbol"></div>
        <div class="promote">{{limitType}}{{$t('exchange.title_2')}}</div>
        <div class="confirm-btn">
          <CommonBtn :btnText="$t('exchange.btn_2')" :loading="btnLoading" @btnClick="promoteLimit"></CommonBtn>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TokenInfo, GetRule, NodeList } from '@/api/system'
import { GetRateApi } from '@/api/user'
import Web3 from "web3"
import coinAbi from '@/utils/coinAbi'
import usdtAbi from '@/utils/usdtAbi'
import quoterAbi from '@/utils/quoterAbi'
// import pledgeAbi from '@/utils/pledgeAbi'
import swapAbi from '@/utils/swapAbi'
export default {
  name: 'Exchange',
  components: {
  },
  computed: {
    ...mapState({
      isLine: state => state.wallet.isLine,
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
      account: state => state.wallet.account,
      ethBalance: state => state.wallet.ethBalance,
      mudBalance: state => state.wallet.mudBalance,
      usdtBalance: state => state.wallet.usdtBalance,
      mudAddr: state => state.wallet.mudAddr,
      usdtAddr: state => state.wallet.usdtAddr,
      pledgeAddr: state => state.wallet.pledgeAddr,
      swapAddr: state => state.wallet.swapAddr,
      quoterAddr: state => state.wallet.quoterAddr,
    }),
    pledgeNum() {
      let pledge = this.pledgeArr || []
      if (pledge.length == 0) return '0'
      if (this.num == '' && pledge) {
        return pledge[this.pledgeIndex].value
      } else {
        return this.num
      }
    },
    pledgeUp() {
      let up = ''
      let pledge = this.pledgeArr || []
      if (pledge.length == 0) return '0%'
      if (this.num == '' && pledge) {
        up = pledge[this.pledgeIndex].rate
      } else {
        if (+this.num < +pledge[this.pledgeIndex].value) {
          up = 0
        } else if (+this.num > +pledge[pledge.length -1].value) {
          up = pledge[pledge.length -1].rate
        } else {
          for(let i = 0; i< (pledge.length - 1);i++) {
            if (+this.num > +pledge[i].value && +this.num < +pledge[i + 1].value) {
              up = pledge[i].rate
            }
          }
        }
      }
      return up + '%'
    },
    pledgeCycle () {
      if (this.tokenInfo.toString() != '{}' && this.tokenInfo.cycle && this.tokenInfo.cycle.length != 0) {
        return this.tokenInfo.cycle[this.cycleIndex]?.value
      } else {
        return '90'
      }
      
    },
    usdtPledge() {
      return (this.usdtToStake / (10 ** 18)).toFixed(4)
    },
    showSlideNum() {
      let num = ''
      let silde = this.slideArr || []
      if (silde.length == 0) {
        num = '0'
      } else if (this.slideNum == '' && silde) {
        num = silde[this.slideIndex].value
      } else {
        num = this.slideNum
      }
      return num
    },
    slippageFactor() {
      let factor = 1000 - (+this.showSlideNum * 10)
      return factor
    }
  },
  watch: {
    pledgeCycle() {
      this.getRate()
    }
  },
  data() {
    return {
      ruleIndex: 5, // 1项目介绍2用户协议3计算公式4如何加速5质押规则6推荐规则7数值说明
      ruleInfo: '',
      tokenInfo: {},
      pledgeIndex: 0, // 质押固定数量
      num: '', // 质押数量
      cycleIndex: 2, // 质押周期
      nodeList: [], // 节点数组
      nodeInfo: {
        'id' : "1",  //节点id
        'name' : '' , // 节点名称
        'logo' : '' , // 节点logo
        'num' : '' , // 节点质押数
      }, // 节点信息
      nodeIndex: 0,
      btnLoading: false,
      param: {
        quote: '', // 质押数量
        cycle: '', // 质押周期(30)
        node: '', // 节点id
        nodeName: '',
      },
      visible: false,
      visibleType: 1, // 弹窗类型 1额度足够 2额度不足

      pledgeArr: [], // 速率数组
      rateInfo: {},
      limitType: '', // MUD 或者 USDT
      usdtToStake: 0,
      slideArr: [
        {
          value: '0.5'
        },
        {
          value: '1'
        },
        {
          value: '3'
        }
      ],
      slideIndex: 0,
      slideNum: '',
      // slippageFactor: '',
    }
  },
  mounted() {
    if(localStorage.getItem('NodeSelect')){
      this.nodeIndex = +localStorage.getItem('NodeSelect')
      let info = JSON.parse(localStorage.getItem('exhcnageInfo'))
      this.pledgeIndex = info.pledgeIndex
      this.num = info.num
      this.cycleIndex = info.cycleIndex
      localStorage.setItem('exhcnageInfo', '')
      localStorage.setItem('NodeSelect', '')
    } else {
      window.scrollTo(0,0)
    }
    this.getData()
    this.changeRuleIndex(5)
    if (this.account) {
      this.SetBalance()
    }

  },
  methods: {
    ...mapActions(['GetUserInfo', 'checkLink', 'setNoticeType', 'SetBalance', 'SetMaskLoading', 'SetLoadingText']),
    async getData() {
      await TokenInfo({type: 2}).then(res => {
        this.tokenInfo = res
      })
      await NodeList().then(res => {
        this.nodeList = res
        if (res.length != 0) {
          this.nodeInfo = res[this.nodeIndex]
          this.getRate()
        }
        
      })
      if (this.account) {
        this.GetUserInfo()
      }
    },
    getRate() {
      GetRateApi({nodeid: this.nodeInfo.id, cycle: this.tokenInfo.cycle[this.cycleIndex].value, type: 2}).then(res => {
        this.pledgeArr = res.pledge
        this.rateInfo = res.info
      })
    },
    changeRuleIndex(index) {
      if (index) this.ruleIndex = index
      GetRule({type: this.ruleIndex}).then(res => {
        this.ruleInfo = res
      })
    },
    pickNode() {
      let info = {
        pledgeIndex: this.pledgeIndex,
        num: this.num,
        cycleIndex: this.cycleIndex,
      }
      localStorage.setItem('exhcnageInfo',JSON.stringify(info))
      this.$router.push('/node?index=' + this.nodeIndex)
    },
    toRecord() {
      this.$router.push('/record')
    },
    async openContent() {
      // 1全部屏蔽质押
      // let flag = true
      // if (flag) {
      //   this.$toast(this.$t('notice.text_14'))
      //   return
      // }
      // 2可质押白名单
      // let whiteArr = [
      //   '0x5156bfed68259d4a6dc72ffcd3905cd2d422d558',
      //   '0x306e1bb641c337a0e2ea1ce4e94d5677e434fa33',
      //   '0x4feae4379ff7918009a6f4a41cfe4df6c175ddf4',
      //   '0x4f883dafc60705b5c852d6cd12317b67f240f8d1',
      //   '0x6eef176ad10e93aae1f9c1641ee1fc6544b060ea',
      //   '0xdaaab3be901265b9364de6e2e26e6f6773409816', // 天使轮测试2
      // ]
      // if (whiteArr.indexOf(this.account.toLowerCase()) == -1) {
      //   this.$toast(this.$t('notice.text_14'))
      //   return
      // }
      this.param = {
        quote: '', // 质押数量
        cycle: '', // 质押周期(30)
        node: '', // 节点id
        nodeName: '',
        txid: '',
      }
      this.param.quote = this.pledgeNum
      this.param.cycle = this.pledgeCycle
      this.param.node = this.nodeInfo.id
      this.param.nodeName = this.nodeInfo.name

      if (+this.param.quote < +this.tokenInfo.min) {
        this.$toast(`${this.$t('exchange.minPledge_2')} ${this.tokenInfo.min}`)
        return
      }
      // isline 离线不判断额度
      if (this.isLine == '1') {
        await this.allowance('MUD').then(async () => {
          if (this.visibleType == 1) {
            await this.allowance('USDT').then(() => {
              if (this.visibleType == 1) {
                try {
                  // 质押数量
                  let amount = this.accMul(this.param.quote)
                  const web3 = new Web3(window.ethereum)
                  const address = this.account
                  let swapContract = new web3.eth.Contract(quoterAbi, this.quoterAddr, {
                    from: address, 
                  });
                  swapContract.methods.quoteExactInputSingle(
                    this.mudAddr,
                    this.usdtAddr,
                    3000,
                    amount,
                    0
                  ).call((error, result) => {
                    console.log('quoteExactInputSingle', error, result)
                    if (!error) {
                      this.usdtToStake = result
                      this.visible = true
                    } else {
                      this.$toast(error)
                    }
                  });
                } catch(error) {
                  // this.btnLoading = false
                  console.error("trigger smart contract error", error)
                  console.log(error)
                  this.$toast(error || 'Do not assign to the exception parameter')
                }
              } else {
                this.visible = true
              }
            })
          } else {
            this.visible = true
          }
        })
      } else {
        this.visibleType = 1
        this.visible = true
      }
      
    },
    async allowance(limitType) {
      return new Promise((resolve) => {
        // 判断剩余质押额度是否足够
        const web3 = new Web3(window.ethereum)
        const address = this.account
        this.limitType = limitType
        let abi = {}
        let addr = ''
        if (limitType == 'MUD') {
          abi = coinAbi
          addr = this.mudAddr
        } else {
          abi = usdtAbi
          addr = this.usdtAddr
        }
        try {
          this.SetMaskLoading(true)
          this.SetLoadingText(this.$t('toast.limit'))
          let myContract = new web3.eth.Contract(abi, addr, {
            from: address, // default from address
            // gasLimit: 100000,
            // gasPrice: 10000000000 // default gas price in wei, 10 gwei in this case
          });
          myContract.methods.allowance(address, this.swapAddr).call((error, result) => {
            console.log('allowance', error, result)
            let amount = this.accMul(this.param.quote)


            // 查询剩余质押额度
            let limit = result
            this.SetMaskLoading(false)
            console.log(limit, amount)
            //  && limitType == 'MUD'
            if (+limit >= +amount) {
              // 额度足够发起质押
              this.visibleType = 1
              resolve(1)
            } else {
              // 额度不足提高额度
              this.visibleType = 2
              resolve(2)
            }
          });
        } catch(error) {
          // this.btnLoading = false
          console.error("trigger smart contract error", error)
          this.$toast(error || 'Do not assign to the exception parameter')
        }
      })
    },
    async promoteLimit() {
      this.checkLink().then(async () => {
        const web3 = new Web3(window.ethereum)
        const address = this.account
        // 提升额度
        try {
          this.SetMaskLoading(true)
          this.SetLoadingText(this.$t('toast.limit_2'))
          this.btnLoading = true
          let abi = {}
          let addr = ''
          let val = 0
          if (this.limitType == 'MUD') {
            abi = coinAbi
            addr = this.mudAddr
            val = '999999999999999'
          } else {
            abi = usdtAbi
            addr = this.usdtAddr
            // val = 999999999 * (10 ** 7) + ''
            val = '9999999999999999999999999'
          }

          let myContract = new web3.eth.Contract(abi, addr, {
            from: address, 
          });
          myContract.methods.approve(this.swapAddr, val).send((error, result) => {
            console.log('approve', error, result)
            if (!error) {
              this.SetMaskLoading(false)
              this.$toast(this.$t('toast.pledgeSuccess'))
              this.visible = false
            } else {
              this.btnLoading = false
              this.SetMaskLoading(false)
              this.$toast(error.message)
              console.log('error', error)
            }
            
          });
        } catch(error) {
          console.error("trigger smart contract error", error)
          this.$toast(error || 'Do not assign to the exception parameter')
        }
      })
    },
    async buyTokens() {
      this.checkLink().then(async () => {
        if (+this.param.quote < +this.tokenInfo.min) {
          this.$toast(`${this.$t('exchange.minPledge_2')} ${this.tokenInfo.min}`)
          return
        }
        console.log(+this.usdtBalance, +this.usdtPledge, +this.mudBalance, +this.param.quote)
        if (+this.usdtBalance < +this.usdtPledge || +this.mudBalance < +this.param.quote) {
          this.$toast(`${this.$t('toast.lowBalance')}`)
          return
        }

        // isLine 1正式走合约 2线下模式
        if (this.isLine == 1) {
          // 质押
          try {
            // 质押数量
            let amount = this.accMul(this.param.quote)
            const web3 = new Web3(window.ethereum)
            const address = this.account
            this.btnLoading = true
            let myContract = new web3.eth.Contract(swapAbi, this.swapAddr, {
              from: address, 
            });
            console.log(this.usdtToStake,
              amount,
              this.param.cycle / 30,
              this.nodeInfo.id,
              this.slippageFactor)
            myContract.methods.createNewStakingContract(
              this.usdtToStake,
              amount,
              this.param.cycle / 30,
              this.nodeInfo.id,
              this.slippageFactor
            ).send((error, result) => {
              console.log('createNewStakingContract', error, result)
              if (!error) {
                this.$toast(this.$t('toast.pledgeSuccess'))
                let res = {
                  quote: this.pledgeNum,
                  usdt: this.usdtPledge,
                  cycle: this.param.cycle,
                  node: this.nodeInfo.name,
                  rate: this.pledgeUp,
                  hash: result,
                  pageType: 2
                }
                console.log(res)
                this.SetMaskLoading(true)
                this.SetLoadingText(this.$t('toast.submit'))
                this.SetMaskLoading(false)
                this.$router.push('/success?data=' + JSON.stringify(res))
                this.GetUserInfo()
                this.pledgeIndex = 0
                this.nodeIndex = 0
                this.num = ''
                this.btnLoading = false
                this.visible = false
              } else {
                this.SetMaskLoading(false)
                // error = this.$t('toast.dealFailed')
                this.$toast(error.message)
                this.btnLoading = false
              }
              
            });
          } catch(error) {
            // this.btnLoading = false
            console.error("trigger smart contract error", error)
            console.log(error)
            this.$toast(error || 'Do not assign to the exception parameter')
          }
        } else {
          // 屏蔽质押
          this.$toast(this.$t('notice.text_14'))
        }
        
      })
    },
    accMul(arg1) {
      // 乘以10^6次方
      var m=0,s1=arg1.toString()
      try{
        m+=s1.split(".")[1].length
      }catch(e){
        // console.log(e)
      }
      let final = Number(s1.replace(".",""))*Math.pow(10,6)/Math.pow(10,m)
      // 转16进制
      // let result = final.toString(16)
      return final + ''
    },
    toPath(url) {
      this.$router.push(url)
    },
    openNotice() {
      this.setNoticeType(8)
    }
  }
}
</script>
<style lang="scss" scoped>
:root {
  --van-overlay-background-color: rgba(0, 0, 0, .4) ;
}
.Exchange {
  min-height: calc(100vh - 45px);
  padding: 0 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;

  .slide-tips {
    width: 100%;
    margin: -10px 0 25px 0;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #EDC67F;
  }
  .step {
    width: 100%;
    margin-bottom: 38px;
    .step-top {
      display:flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 0 15px;
      .st-circle {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background: #2C2F34;
        box-shadow: 0px -3px 6px rgba(147, 147, 147, 0.25), 0px 4px 4px #000000;
        border-radius: 50%;
        .stc-in {
          width: 12px;
          height: 12px;
          background: linear-gradient(180deg, #EFBA5B 0%, #EDC57C 100%);
          box-shadow: inset 0px 4px 4px #B16D00;
          border-radius: 50%;
        }
      }
      .st-line {
        width: 80px;
        height: 4px;
        background: rgba(36, 37, 40, 0.7);
        box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.08), inset 2px 2px 4px rgba(0, 0, 0, 0.8);
        border-radius: 25px;
      }
    }
    .step-bottom {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .sb-item {
        // flex: 1;
        min-width: 65px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #A5A8AA;
      }
    }
  }
  .rate {
    width: 100%;
    margin: 0px 0 20px 0;
    padding: 10px 10px;
    border-radius: 14px;
    background: rgba(237, 169, 0, 0.3);
    color: #EDC67F;
    font-size: 13px;
    line-height: 22px;
  }
  .main {
    width: 100%;
    padding: 10px 28px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;;
    border-radius: 40px;
    margin-bottom: 26px;
    .main-title {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #A5A8AA;
      .mt-select {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: center;

        color: #EDC67F;
      }
      .mt-text {

      }
      .mt-balance {
        flex: 1;
        margin: 0 16px;
        text-align: right;
      }
      .mt-img {
        width: 20px;
        height: 20px;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
      }
    }
    .main-symbol {
      width: 29px;
      height: 6px;
      margin: 10px 0;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    .main-list {
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .main-item {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 89px;
        height: 36px;
        margin: 8px 0;
        background: linear-gradient(320.66deg, #8D8D8D 14.75%, #414141 84.81%);
        box-shadow: -1px -2px 7px rgba(254, 254, 254, 0.25), 0px 4px 8px #000000, inset 0px 1px 1px #B0B0B0, inset 0px -2px 1px #292929;
        border-radius: 10px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #D9D9D9;
        .mi-rate {
          position: absolute;
          right: 0;
          top: 0;
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 29px;
          height: 13px;
          background: #F84F26;
          border-radius: 0px 10px 0px 5px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          color: #FFFFFF;
        }
      }
      .main-item-active {
        background: linear-gradient(320.66deg, #F6DFA9 14.75%, #EDC67F 84.81%);
        box-shadow: -1px -2px 7px rgba(254, 254, 254, 0.25), 0px 4px 8px #000000, inset 0px 1px 1px #FFFAEB, inset 0px -2px 1px #9E865A;
        border-radius: 10px;
        color: #75521A;
      }
    }
    .main-input {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 49px;
      padding: 0 16px;
      margin: 15px 0;
      background: #292D32;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
      border-radius: 10px;
      input {
        flex: 1;
        border: none;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        background: transparent;
        color: #B8B6B2;
      }
      .mi-all {
        margin-left: 20px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #EFBB5E;
      }
    }
    .main-node {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 56px;
      padding: 0 16px;
      background: #292D32;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
      border-radius: 10px;
      .mn-logo {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #D9D9D9;
      }
      .mn-name {
        flex: 1;
        margin: 0 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B8B6B2;
      }
      .mn-img {
        width: 22px;
        height: 22px;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
      }
    }
    .main-tips {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 25px 0;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;

      color: #B8B6B2;
      img {
        width: 14px;
        // height: 18px;
        margin-left: 5px;
      }
    }
    .main-btn {
      width: 100%;
      height: 43px;
      margin: 10px 0;
    }
    .main-record {
      margin: 15px 0;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;

      color: #EDC67F;
    }
  }
  .rule {
    width: 100%;
    padding: 10px 28px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    margin-bottom: 26px;
    .rule-head {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0;
      .rh-item {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 92px;
        height: 36px;
        padding: 0 8px;
        background: #292D32;
        box-shadow: 0px -1px 4px rgba(167, 167, 167, 0.19), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 100px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #D9D9D9;
        text-align: center;
      }
      .rh-item-active {
        background: linear-gradient(320.66deg, #F6DFA9 14.75%, #EDC67F 84.81%);
        box-shadow: -1px -2px 7px rgba(254, 254, 254, 0.25), 0px 4px 8px #000000, inset 0px 1px 1px #FFFAEB, inset 0px -2px 1px #9E865A;
        border-radius: 100px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #75521A;
      }
    }
    .rule-main {
      min-height: 200px;
      margin-bottom: 20px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;

      color: #B8B6B2;
    }
  }

  .ConfirmContent {
    border-radius: 40px 40px 0px 0px;
    .content {
      padding: 22px 24px;
      background: #292D32;
      box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.66);
    }
    .head {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      img {
        width: 22px;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
      }
    }
    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
    .symbol {
      width: 29px;
      height: 6px;
      margin: 14px 0;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    .promote {
      font-family: 'PingFang SC';
      font-style: normal;
      font-size: 14px;
      line-height: 22px;
      color: #FFFFFF;
    }
    .list {
      width: 100%;
      .item {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
        .key {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #B8B6B2;
        }
        .value {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
        }
      }
    }
    .line {
      width: 100%;
      height: 4px;
      margin: 18px 0;
      background: rgba(36, 37, 40, 0.7);
      box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.08), inset 2px 2px 4px rgba(0, 0, 0, 0.8);
      border-radius: 25px;
    }
    .notice {
      display:flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      // margin-bottom: 22px;
      margin-right: 8px;
      img {
        width: 25px;
        height: 25px;
        background: linear-gradient(320.66deg, #FE4F4F 14.75%, #F72424 84.81%);
        border: 1px solid #BF0202;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
      }
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FF5C5C !important;
    }

    .confirm-btn {
      width: 308px;
      height: 46px;
      margin: 0 auto;
      margin-top: 22px;
    }
  }
}
</style>

